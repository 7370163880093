@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f6f9ff;
}

* {
	margin: 0;
	padding: 0;
	font-family: "inter";
}

.user_input {
	border: 1px solid rgb(184, 184, 184);
	border-radius: 5px;
	outline: none;
	width: 500px;
	padding: 2px 10px;
}


.customModal{
	max-width: 90%;
	width: 90%;
	height: 800px; 
	border-radius: 5px;
}


.dropzone {
	width: 300px;
	height: 250px;
	border: 1px dashed #999;
	border-radius: 3px;
	text-align: center;
}

.upload-icon {
	margin-top : 50px;
	width: 100px;
	height: 80px;
}

.upload-input {
	position: relative;
	top: -150px;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.h {
	height: 100px;
}