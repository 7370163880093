.ql-editor p {
  margin-bottom: 1rem;
}

.ql-editor h1 {
  margin-bottom: 1.5rem;
}
.ql-editor h2 {
  padding-top: 1.25rem;
  margin-bottom: 1rem;
}

.ql-editor h3 {
  margin-bottom: 1rem;
}
.ql-editor h4 {
  margin-bottom: 0.75rem;
}
